const state = {
 
};


const mutations = {
 
};

const actions = {
 
};

export default {
  namespaced: true,   //允许命名store模块
  state,
  mutations,
  actions
}
